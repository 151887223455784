import { FC, ReactNode, useCallback, useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

const WalletConnectionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const network =
    process.env.NEXT_PUBLIC_SOLANA_CLUSTER === 'mainnet' ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(
    () => {
      return process.env.NEXT_PUBLIC_RPC_URL as string;
    }
    , [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [],
    [network]
  );

  const onError = useCallback((error: WalletError) => {
    console.log("Caught Error in Provider", error.name);
  }, []);

  return (
    <ConnectionProvider endpoint={endpoint} >
      <WalletProvider onError={onError} wallets={wallets} autoConnect>
        {children}
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default WalletConnectionProvider;