import { createGlobalStyle } from 'styled-components';
import resetCss from 'styles/reset';

import theme, { bp, color } from 'settings/theme';

const GlobalStyle = createGlobalStyle`
  ${resetCss}

  html, body {
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0 !important;
    color: ${color.gray(15)};
    letter-spacing: -0.04em;
    font-weight: 400;
    background-color: var(--primary-primary-90, #201F1D);
  }

  html, body, #__next, #__next main{
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
  }

  html{
    overflow: initial !important;
    padding: 0 !important;
  }

  body{
    overflow-y: scroll !important;  
  }

  ::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.05);
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.20);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.25);
  }

  #__next{
    z-index: 0;
  }

  #headlessui-portal-root{
    z-index: 1;
  }


  button{
    font-family: inherit !important;
    cursor: pointer;
    color: white;
  }

  a{
    text-decoration: none;
  }
  
  h1{
    font-size: 1.625rem;
    margin-bottom: 1rem;
  }

`;

export default GlobalStyle;
